import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Layout from '../components/layout'

const RecordTemplate = ({ data }) => {
  const record = data.contentfulRecord

  return (
    <Layout>
      <div className='page-header'>
        <div className='container'>
          <div className='sixteen columns'>
            <h1 className='page-title'>{record.title}</h1>
            <ul className='breadcrumb'>
              <li className='active'>{record.publishDate}</li>
            </ul>
          </div>
        </div>
      </div>

      {/*<div className='container main'>*/}
      {/*  <div className='row'>*/}
      {/*    <div className='sixteen columns post-overview'>*/}
      {/*      <article className='post'>*/}
      {/*        /!*<div className='post-body'>{record.body ? renderRichText(record.body) : ''}</div>*!/*/}
      {/*      </article>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Layout>
  )
}

export const query = graphql`
  query RecordBySlug($slug: String!) {
    contentfulRecord(slug: { eq: $slug }) {
      title
      description {
        description
      }
#      body {
#        raw
#      }
      publishDate(formatString: "DD.MM.YYYY")
    }
  }
`

export default RecordTemplate
